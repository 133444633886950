<template>
  <div class="circle">
    <img class="head-banner" :src="BannerSrc" alt="">
    <van-tabs v-model="active" @change="changeTabs">
      <van-tab title="待办">
        <van-list class="circleList" v-model="loading" :finished="finished" @load="getUnFinishedList"
          style="align-content: center;">
          <VueFlexWaterfall col="2" col-spacing="15" :break-by-container="true" :height="height1">
            <questionnaire-item class="circle-item" v-for="(item,index) in circleList" :key="index" :itemData="item"
                                :isFinish="false" :index="index" />
          </VueFlexWaterfall>
        </van-list>
        <van-empty v-if="circleList.length===0" description="暂无数据" />
      </van-tab>
      <van-tab title="已完成">
        <van-list class="circleList" v-model="loading2" :finished="finished2" @load="getFinishedList"
          style="align-content: center;">
          <VueFlexWaterfall col="2" col-spacing="15" :break-by-container="true" :height="height2">
            <questionnaire-item class="circle-item" v-for="(item,index) in circleList2" :index="index" :key="index"
                                :isFinish="true" :itemData="item" />
          </VueFlexWaterfall>
          <van-empty v-if="circleList2.length===0" description="暂无数据" />
        </van-list>
      </van-tab>
    </van-tabs>

  </div>
</template>

<script>
  import VueFlexWaterfall from 'vue-flex-waterfall'
  import questionnaireItem from './components/questionnaireItem'

  export default {
    name: "circleDetails",
    components: {
      questionnaireItem,
      VueFlexWaterfall
    },
    data() {
      return {
        active: 0,
        loading: false,
        BannerSrc: '',
        finished: false,
        circleList: [],
        loading2: false,
        finished2: false,
        circleList2: [],
        pageSize: 10,
        pageNum1: 1,
        pageNum2: 1,
        height1: null,
        height2: null,
      };
    },
    mounted() {
      this.BannerSrc = this.$route.query.picUrl
      this.getUnFinishedList()
      this.$nextTick(()=>{
      if (sessionStorage.tabNum >= 0) {
        this.active = Number(sessionStorage.tabNum)
        this.changeTabs(this.active)
      }
      })

      // sessionStorage.setItem("isCircle", "true")
    },
    methods: {
      // 获取待办活动
      getUnFinishedList() {
        this.$api.home.unfinishedList({
          communityId: this.$route.query.id,
          pageNum: this.pageNum1,
          pageSize: '100'
        }, res => {
          if (res.code === 200) {
            if (this.circleList.length === res.total) {
              this.finished = true
              return false
            }
            this.circleList = this.circleList.concat(res.rows)
            this.pageNum1++
            // 加载状态结束
            this.loading = false;

            if (this.circleList.length <= 2){
              this.height1 = '300px'
            }else {
              this.height1 = null
            }
          }
        })
      },
      // 获取已完成活动
      getFinishedList() {
        this.$api.home.finishedList({
          communityId: this.$route.query.id,
          pageNum: this.pageNum2,
          pageSize: '100'
        }, res => {
          if (res.code === 200) {
            if (res.rows.length === 0) {
              this.finished2 = true
              return false
            }
            this.circleList2 = this.circleList2.concat(res.rows)
            this.pageNum2++
            // 加载状态结束
            this.loading2 = false;

            if (this.circleList2.length <= 2){
              this.height2 = '300px'
            }else {
              this.height2 = null
            }
          }
        })
      },
      // tab 切换 
      changeTabs(index) {
        sessionStorage.tabNum = index
        if (index === 0) {
          this.finished = false
          this.loading = false
          this.finished2 = true
          this.loading2 = true
        } else {
          this.finished = true
          this.loading = true
          this.finished2 = false
          this.loading2 = false
        }
      }

    },



  };
</script>

<style lang="less" scoped>
  .circle {
    width: 100%;
    box-sizing: border-box;
    background-color: #F1F1F1;
    min-height: 100vh;

    .head-banner {
      width: 100%;
      height: 340px;
      object-fit: cover;
    }

    .circle-item {
      display: block;
      margin-bottom: 24px;
      display: block;
    }
  }

  .circle /deep/ .van-list__loading {
    width: 100%;
    display: block;
    margin: 0 auto;
    text-align: center;

  }

  .circle /deep/ .van-tabs__content {
    padding: 30px 30px 0;
  }
</style>
